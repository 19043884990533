body {
	font-family: $fontTwo;
}
#ht-statview {
width: 100% !important; 
}

.ht-stats {	
/*    margin: -14px 10px 0 0; */
/*    float: right; */
}
.ht-stats-mobile {
	width: 82% !important;
	font-size: 100%;
	margin: 5px auto;
	
}
.ht-title {
	text-align: left;
    padding-left: 10px;
    width: 100%;
    white-space: nowrap;
    font: 100 30px/27px 'proxima-nova', sans-serif;
    color: #fff;
    text-transform: uppercase;
/*    background: url('statview-bg.png') 0 0; */
    background: $link-color;
    margin-bottom: -25px;
    height: 65px;
    line-height: 40px;
    padding-left: 8px;
    z-index: 0;
    position: relative;
    white-space: nowrap;
    font-weight:bold;
    margin-bottom: 5px;
    display: none;
}
.ht-date-controls {
    float: left;
    width: 350px;
    padding: 0;
    margin: 0 10px 0 7px;
}
.ht-current-date {
	color:#fff;
}

.ht-check {
	padding-right: 5px !important;
    padding-left: 0px !important;
}

/* grouped table row header background and font colors */
.ht-table th.ht-title-row {
	background-color: #000;
	color: #fff;
}
/* table header background color and font colors */
.ht-table th,
.ht-table-summary th {
	border-top: 1px solid $link-color;     
    background-color: $link-color;
    color: #fff;   
}

/* table header link font color */
.ht-table th a {
    color: #fff;
}
/* bottom table border color */
.ht-table tr {
	border-bottom: 1px solid $ltGrey2;
}
/* table row hover color */
.ht-table tr:hover {
	background: #ffeac2;
}
/* table odd row color */
.ht-odd-row {
	background-color: #f6f6f6;
}
/* table even row color */
.ht-even-row {
	background-color: #fff;
}
/* table column highlight background and font colors */
[data-high=true] {	
	background-color: rgba(252,197,89,.5) !important;
	color: #000 !important;
	border-left: 1px solid $link-color;
    border-right: 1px solid $link-color;
    border-bottom: 1px solid #444444;    
}
/* nav bar header color */
.ht-controls {	
	background-color: #444444;	
}
/* button background and font colors */
.ht-btn, .ht-btn-submit, .ht-btn-filter {
	background-color: #000;	
	color: #fff;
}
/* active button background and font colors */
.htactive, .ht-btn-submit:hover, .ht-btn-filter:hover {
	background-color: $link-color;
	color: #fff !important;
	font-weight: normal;
}
/* button border color */
.ht-col-left ul li, 
.ht-btn-submit,
.ht-btn-filter,
.ht-filter-row li {
	border: 1px solid #000;
}

/************* Player Profile *************/

/* bottom thick border around bio */
.ht-player-details {
	border-bottom: 10px solid $link-color;
	background-color: #fff;
}

.ht-player-profile {
	font-size: 120%;
}

/* gradient top background color */
/** http://www.colorzilla.com/gradient-editor/ **/
.ht-background {
	background: #fff;
	/*background: -moz-linear-gradient(left,  rgba(146, 17, 0, 0.45) 0%,rgba(125,185,232,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(146, 17, 0, 0.45) 0%,rgba(125,185,232,0) 100%);
	background: linear-gradient(to right,  rgba(146, 17, 0, 0.45) 0%,rgba(125,185,232,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$link-color', endColorstr='#007db9e8',GradientType=1 );*/
}
/* player name color */
.ht-player-summary h2 {
	color: #fff;
}
/* team name color */
.ht-player-summary h3 {
	color: #444444;
}
/* player bio font color ie Position, Shoots etc. */
.ht-player-details .ht-player-bio {
	color: #333;
}
/* border around the image */
.ht-player-details .ht-player-image {
	border: 4px solid $link-color;
}
/* jersey number color and right border */
.ht-jersey {
	color: #fff;
	border-right: 1px solid $link-color;
}
/* stats right side border */
.ht-player-details .ht-row-stats ul li {
	border-right: 1px solid $link-color;
}
/* stats title color */
.ht-player-details .ht-row-stats ul li span,
.ht-player-details .ht-row-stats-mobile-l2 ul li span {
	color: #000;
}
/* group title headers background and font color */
.ht-player-data .ht-stats-header {
	background-color: #444444;
	color: #fff;
}
/* media background color */
.ht-player-media-buttons {
	background-color: #000;
}
/* media buttons font color */
.ht-player-media-buttons ul li a {
	color: #fff;
}
/* line color between media buttons */
.ht-player-media-buttons ul li {
	border-right: 1px solid #444444;
}
/* media buttons hover color */
.ht-player-media-buttons ul li:hover {
	background-color: $link-color;
}
/* currently selected media button color */
.ht-button-current {
	background-color: $link-color;
}
.edit_mode{
  .ng-scope{
    input[type="checkbox"]+label {
    	color: #000;
    }
  }
}
.ng-scope{
  input[type="checkbox"]+label {
  	color: #fff;
  }
  input[type="checkbox"]+label:before {
  	margin: -1px 5px 0 -28px;
  }
}

#ht-select-player-type {
	width: 100px;
}

/* mobile font-size overrides */

#ht-month {
	width: 85px;
}

.ht-current-date-mobile {
	font-size: 110%;
}
.ht-controls select, .ht-stats-header select {
	font-size: 95%;
	padding: 0 0 0 4px;
	width: 190px;
}
.ht-daily-sch-page-mobile {
	font-size: 115%;
}
.ht-table th, .ht-table td, .ht-table-summary th, .ht-table-summary td {
	font-size: 110%;
}
.ht-player-details-mobile {
    font-size: 100% !important;
}
.ht-player-jersey-number {
	padding-top: 23px;
}
.ht-matchup-container {
	background-color: #fff;
}

/* override the playoff middle alignment to top */
.ht-expanded-layout .ht-expanded-columns-container .ht-expanded-column {
	vertical-align: top;
}
.nav{
  padding-left: 0px;
  width: 100%;
  display: block;
  height: auto;
}
/*  */
html, body {
			min-height: 100%;
			margin: 0;
			overflow-x: hidden;
			
		}
		.ht-main-header {
			background: #000;
			color: #fff;  
			position: fixed;
			height: 40px;
			line-height: 40px;
			width: 100%;
			top: 0;
			left: 220px;
			right: 0;
			z-index: 1;
			transition: all 0.3s ease;
		}
		.ht-main-header a {
		    position: absolute;
		    left: 20px;		    
		    color: #fff;
		    font-size: 16px;
		}
		.ht-page-wrap {  
			transition: width 0.3s ease;
		}
		.ht-left-nav {
			position: fixed;
			top: 0;
			width: 220px;
			height: 100%;
			background: #2D2D2D;			
			overflow-x: hidden;
			overflow-y: hidden;
			box-sizing: border-box;
			transition: width 0.3s ease;
		}
		.ht-left-nav form {
			border-bottom: 1px solid #000;
    		padding-bottom: 10px;
		}
		.open-menu-display {
			display: block;
		}
		.open-menu-none {
		  display: none;
		}
		.close-menu-display {
			display: block;
		}
		.close-menu-none {
			display: none;
		}
		.isMenuClosed {
			width: 0%;
		}
		.isMenuClosedPage {
			width: 100%;
		  	margin-left: 0;
		}
		.isMenuClosedHeader {
			width: 100%;
		  	left: 0%;
		}
		.ht-stats {
			width: 100%;
		}
		.ht-stats-mobile {
			width: 95% !important;
			font-size: 85%;
		}
		.ht-left-nav ul {
			list-style: none;
			margin: 50px 0 0;
			padding: 0;
			width: 100%;			
		}
		.ht-left-nav ul li {					
			list-style: none;
		}
		.ht-left-nav ul li a {
			display: block;
			width: 100%;
			padding: 10px 0 10px 15px;
			color: #fff;
			text-decoration: none;
		}
		.ht-left-nav ul li a:hover {
			background-color: #000;
			color: #fff;
		}
		.ht-left-nav .activeMenu {
			background-color: #fff;
			color: #000;
		}
		.ht-left-nav ul li select {
			height: 28px;
			border: 1px solid #000;
			padding-left: 2px;
			width: 100px;
			background-color: #fff;	
		    border-radius: 0;    
		    outline: 0;
		    font-size: 100%;
		}		
		.selectbox {
			color:#fff;
			padding: 5px 0 5px 15px;
		}
		.selectbox span {
			width: 55px;
		    display: inline-block;
		    text-align: right;
		    padding-right: 4px;
		}
		.ht-left-nav p {
			font-size: 80%;
			color: #999;
			padding: 0 15px;
			word-break: break-word;
			word-break: break-all;
		}
		hr {
			clear: both;
		}
		
//Leaders-extended
.ht-leaders-container{
  font-family: $fontOne;
  width: 100%;
  padding: 0px;
  span.ht-leaders-title{
    font: $fontOne;
  }
  .ht-leaders-section{
    border: 1px solid $ltGrey2;
    .ht-leader-stat-header{
      a{
        font:normal 14px/1.5em $fontTwo;
      }
      border-bottom: 1px solid $ltGrey2;
      background: $ltGrey1;
      //display: none;
      .ht-col-left{
        color: $dkGray;
      }
      .ht-col-right{
        //display: none;
      }
    }
    .ht-leaders-stats-container{
      tr{
        border-bottom: 1px solid $ltGrey2;
        &:last-child{
          border: none;
        }
        .ht-cell-center{
          
        }
        .ht-leaders-info{
          .ht-leader-extended-photo{
            border-radius: 100%;
            border: 3px solid $ltGrey2;
            img{
              
            }
          }
          .ht-leaders-player{
            a{
              font:bold 1em/1.5em $fontTwo;
            }
          }
          .ht-leaders-team{
            a{
              
            }
          }
        }
        .ht-leaders-stat{
          span{
            font:bold 30px/1.5em $fontTwo;
            color: $link-color;
          }
        }
      }
    }
  }
}
#ushl-daily-schedule{
  display: block;
}
#ushl-game-center{
  
}
#ushl-goalie-stats{
  
}
#ushl-leaders-extended{

}
#ushl-leaders-multi{
  //
}
#ushl-leaders{
  display: block;
}
#ushl-person-search{
  
}
#ushl-player-stats{
  
}
#ushl-player{
  
}
#ushl-playoffs{
  
}
#ushl-roster{
  
}
#ushl-streaks{
  
}
#ushl-team-stats{
  
}
#ushl-transactions{
  
}
#scorecontainer{
  //display: none;
  .results-title{
    padding-bottom: 0px;
  }
  .score-box{
    border-left:1px solid #fff;
    border-right:1px solid #fff;
    .inner-score-box{
      border: 0px;
      .score-content{
        padding: 10px 15px;
      }
    }
  }
  .game-date{
    background: transparent;
    text-transform: uppercase;
    color: #000;
    &.bottom{
      bottom:20px;
    }
    span{
      font-style: italic !important;
      font:normal 12px/1.5em $fontTwo;
    }
  }
  background: $ltGrey1;
  .slick-prev, .slick-next{
    top:0;
    height: 100%;
    background: $link-color;
    &:before{
      color: #fff;
      font:bold 28px/1.5em FontAwesome;
    }
    &.slick-disabled{
      &:before{
        opacity: 0.8;
      }
    }
  }
  .score-box{
    padding: 0px;
    //min-width: 150px;
    .box-tickets{
      position: relative;
      display: none;
    }
    .inner-score-box{
      background: $ltGrey1;
      border-bottom: 0px;
      a{
        display: block;
        height: 100%;
      }
      &:hover{
        cursor: default;
      }
      .score-content{
        padding-top: 15px;
        .team{
          //font-size: 18px;
          &.game-winner{
            color: $link-color;
          }
        }
      }
    }
  }
  .team-score{
    width: auto;
    height: auto;
    background: transparent;
  }
}